<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="graduations"
      hide-default-footer
      sort-by="number"
      class="elevation-1 ml-4"
    >
      <template v-slot:item.name="{ item }">
        <p @click="loadSingleGraduation(item)" class="btn blue--text">{{ item.name }}</p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    privileges: null,
    headers: [
      {
        text: 'No',
        align: 'start',
        sortable: true,
        value: 'number'
      },
      { text: 'Graduation Name', value: 'name' },
      { text: 'Graduation date', value: 'date' },
      { text: 'Intake', value: 'intake.name' },
      { text: 'Center', value: 'intake.center' },
      { text: 'Comment', value: 'comment' },
      { text: 'Status', value: 'approvalStatus' }
    ],
    graduations: [],
    statuses: ['OPEN', 'CLOSED'],
    graduationDate: null,
    retrievedGraduationDate: null,
    graduationDateMenu: false
  }),

  computed: {
    formattedGraduationDate () {
      return this.graduationDate ? this.$moment(this.graduationDate).format('DD-MMM-YYYY')
        : this.retrievedGraduationDate ? this.$moment(this.retrievedGraduationDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'currentCenter'])
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  created () {
    this.loadGraduations()
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setStoreGraduation: 'SET_STORE_GRADUATION',
      setBtnControl: 'SET_BTNCONTROL'
    }),
    loadGraduations () {
      this.$delinquentService.fetchGraduations(null, null, null, null, 'REQUESTED')
        .then(response => {
          if (!response.status) {
            this.graduations = response.content
            this.graduations.forEach(element => {
              element.number = this.graduations.indexOf(element) + 1
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    loadSingleGraduation (item) {
      this.editedGraduationIndex = this.graduations.indexOf(item)
      this.graduationItem = item
      this.$delinquentService.fetchGraduationById(item.id, 'graduands', 0, 20)
        .then(response => {
          if (response.id) {
            response.graduands.content.forEach(element => {
              element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
              element.receiverNames = element.receiver.firstName + ' ' + element.receiver.lastName
              element.trade = element.trainingEnrolments == null ? '' : element.trainingEnrolments[0].trainingClass.training.name
              element.delinquent.age = parseInt(this.$moment(new Date().toISOString().substr(0, 10)).format('YYYY')) - parseInt(this.$moment(element.delinquent.dateOfBirth).format('YYYY'))
            })
            this.setStoreGraduation(response)
            this.$router.push('/graduation/single-graduation')
            this.setBtnControl(true)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('APPROVE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
